<template>
  <div class="flex flex-col min-h-screen">
    <!-- Card  -->
    <div v-if="payment_link" class="flex flex-col items-center min-h-screen bg-gray-800 md:px-0 py-20">
      <!-- Payment Link  -->
      <div class="m-auto w-11/12 max-w-full sm:max-w-xl">
        <!-- Payment Link Status  -->
        <div v-if="payment_link.status_id == 2" class="
            flex flex-col
            bg-yellow-600
            text-white text-center
            rounded-md
            w-full
            my-4
            py-4
          ">
          {{ payment_link.status }}
        </div>

        <!-- Payment Link Status Paid  -->
        <div v-if="payment_link.paid" class="
            flex flex-col
            bg-success
            text-white text-center
            rounded-md
            w-full
            my-4
            py-4
          ">
          {{ $t("payment.link.paid") }}
        </div>

        <!-- Payment Link Details  -->
        <div class="flex flex-col bg-white rounded-md w-full">
          <div class="py-5 px-4 flex items-center justify-center">
            <app-image v-if="getBusinessLogo" :src="getBusinessLogo" class="flex-none w-16 bg-white" />
            <app-image v-else-if="getBusinessIcon" :src="getBusinessIcon" class="flex-none w-16 bg-white" />
            <div v-else class="flex-1 flex space-x-2 items-center">
              <app-text-icon :text="business?.name" icon_outline="BriefcaseIcon" />
            </div>
          </div>
          <div class="border-t w-full mb-2"></div>
          <div class="flex flex-row px-4 py-6 justify-between">
            <div class="flex flex-col space-y-2">
              <div class="flex flex-row items-center space-x-1.5">
                <p class="text-sm pb-1">
                  {{ $t("payment.link.amount_to_pay") }}
                </p>
                <app-test-mode-badge :testMode="testMode" />
              </div>
              <p class="text-2xl font-semibold">
                {{
                    $formats.formattedCurrency(
                      payment_link.currency,
                      payment_link.formatted_amount ?? 0.0
                    )
                }}
              </p>

              <div class="flex flex-col">
                <div class="flex flex-row space-x-5 items-top">
                  <p class="text-sm text-gray-400 w-8">
                    {{ $t("general.from") }}
                  </p>
                  <p class="text-sm">{{ business?.name }}</p>
                </div>

                <div class="flex flex-row space-x-5 items-top">
                  <p class="text-sm text-gray-400 w-8">
                    {{ $t("general.to") }}
                  </p>
                  <p class="text-sm">{{ payment_link.email }}</p>
                </div>

                <div class="flex flex-row space-x-5 items-top">
                  <p class="text-sm text-gray-400 w-8">
                    {{ $t("payment.link.phone_no") }}
                  </p>
                  <p class="text-sm">{{ payment_link.phone_no }}</p>
                </div>
              </div>
            </div>
            <app-receipt />
          </div>
          <div class="border-t w-full mb-2"></div>
          <div class="flex flex-col px-4 py-2">
            <p class="text-sm font-medium">{{ payment_link.title }}</p>
            <app-text-readmore class="text-gray-500" :text="payment_link.description ? payment_link.description : '-'" />
            <div class="border-t w-full mb-2 mt-2 flex flex-col break-all">
              <button class="text-left flex flex-row items-center mt-2 mb-2" @click="toggleDetails">
                <p class="text-sm text-gray-400">
                  {{ $t("general.view_more_details") }}
                </p>
                <app-icon name="ChevronRightIcon" class="h-5 w-5 text-gray-400 transition transform"
                  :class="[showDetails ? '-rotate-90' : 'rotate-0']" />
              </button>
              <div class="flex flex-row space-x-2 items-center" v-if="showDetails">
                <p class="text-sm text-gray-400 w-28">
                  {{ payment_link.reference ? payment_link.reference : $t("payment.link.ref1") }}
                </p>
              </div>

              <div class="flex flex-row space-x-2 items-center" v-if="showDetails">
                <p class="text-sm text-gray-400 w-28">
                  {{ payment_link.reference_2 ? payment_link.reference_2 : $t("payment.link.ref2") }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Payment Method  -->
        <div v-if="!payment_link.paid && payment_link.status_id != 2" class="
            space-y-4
            flex flex-col
            bg-white
            rounded-md
            w-full
            mt-4
            px-6
            py-6
          ">
          <!-- Proceed Payment Method -->
          <app-proceed-payment-method v-if="payment_methods" :payment_methods="payment_methods"
            @proceed_payment="proceedPayment" :preview_mode="previewMode" :test_mode="testMode" :errors="errors"
            :loading="loading" />
          <div v-else>
            <p class="text-gray-400 py-1">
              {{
                  $t(
                    "payment.open-payment-link.payment_page.link_inactive_support"
                  )
              }}
            </p>

            <a class="text-bluelink" href="mailto:{{business?.support_detail?.support_email}}">
              {{ business.support_detail.support_email }}
            </a>
          </div>
        </div>
        <div class="justify-center mt-10">
          <app-logo-powered-by-swipe-light />
        </div>
      </div>
    </div>

    <!-- Show No Results -->
    <div v-else class="m-auto w-11/12 max-w-full rounded-lg sm:max-w-4xl">
      <div class="p-20 bg-white rounded-lg flex flex-col items-center space-y-4">
        <app-icon-invalid-link />
        <p class="text-xl">
          {{ $t("payment.open-payment-link.payment_page.link_invalid") }}
        </p>
      </div>
    </div>

    <!-- Logo powered by swipe -->
    <div class="block justify-center my-10" v-if="!hasPaymentLink">
      <app-logo-powered-by-swipe-dark />
    </div>
  </div>
</template>
<script>

export default {
  props: {
    previewMode: {
      type: Boolean,
      default: false,
    },
    masterBusinessMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDetails: false,
      testMode: false
    };
  },

  async mounted() {
    if (this.$route.query.id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }

    if (this.$route.query.test != null && this.$route.query.test == "true") {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);

    await this.getDataForPayment(this.$route.query.id);
  },

  computed: {
    loading() {
      return this.$store.getters["paymentStore/loading"];
    },

    payment_link() {
      return this.$store.getters["paymentStore/payment_link"];
    },

    business() {
      return this.masterBusinessMode
        ? this.payment_link.business
        : this.$store.getters["businessStore/business"];
    },

    getBusinessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },

    getBusinessIcon() {
      return this.business?.media?.find(
        (media) => media.collection_name == "icon"
      )?.original_url;
    },

    payment_methods() {
      return this.$store.getters["paymentMethodStore/payment_methods"];
    },

    background() {
      return this.hasPaymentLink ? "bg-gray-800" : "bg-white";
    },

    hasPaymentLink() {
      return this.payment_link != null;
    },

    errors() {
      return this.$store.getters["paymentStore/errors"];
    },

    stripe() {
      return this.$store.getters["paymentStore/stripe"];
    }
  },

  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },

    async proceedPayment(data) {
      if (
        this.previewMode ||
        this.payment_link._id == undefined ||
        data.business_payment_gateway_method_id == 0
      ) {
        return;
      }

      this.$store.commit("paymentStore/resetState", {});

      const paymentAttempt = await this.$store.dispatch(
        "paymentStore/proceedPaymentAttempt",
        {
          businessPaymentGatewayMethodId:
            data.business_payment_gateway_method_id,
          bank_info: data.bank_info,
          stripe_create_payment_result: data.stripe_create_payment_result
        }
      );

      if (paymentAttempt == null) {
        return;
      }

      if (paymentAttempt.status == "requires_action") {
        await data.stripe.confirmCardPayment(
          paymentAttempt.meta.intent.client_secret
        );
      }

      window.location.href = paymentAttempt.process_payment_url;

    },

    async getDataForPayment(paymentLinkId) {
      let paymentLink = await this.$store.dispatch(
        "paymentStore/getPaymentLinkPublic",
        paymentLinkId
      );

      if (this.$lodash.isEmpty(paymentLink.data)) {
        this.$store.commit("paymentStore/setPaymentLink", null);
        return;
      }

      this.$store.commit(
        "businessStore/setBusiness",
        this.payment_link.business
      );
      await this.$store.dispatch(
        "paymentMethodStore/getPaymentMethods",
        this.business.id
      );
    },

    async getDataForPreview() {
      await this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );
      await this.$store.dispatch(
        "paymentMethodStore/getPaymentMethods",
        this.business.id
      );
    },
  },
};
</script>
